/**
* Example:
* In case of usage of same font name for all font extensions or can be different font name per extension
*
* $font-list: (
*    ('CircularStd', ("woff2": "robotoRegular", "woff": "robotRegular2", "ttf": "robotoRegular3"), normal, normal),
*    ('CircularStd', 'CircularStd-Medium', 500, normal),
*    ('CircularStd', 'CircularStd-Bold', bold, normal)
*) !default;
*
**/
//stylelint-disable lora/variable-pattern
$font-primary: "CircularStd", sans-serif !default;
$font-secondary: 'Roboto', sans-serif !default;

$font-list: (
    ('CircularStd', 'CircularStd-Book', normal, normal),
    ('CircularStd', 'CircularStd-Medium', 500, normal),
    ('CircularStd', 'CircularStd-Bold', bold, normal)
) !default;

@mixin lora-fonts {
    @each $font-family, $font-file, $font-weight, $font-style in $font-list {
        @include font-face($font-family, $font-file, $font-weight, $font-style);
    }
}